<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['name', { rules: [{ required: true, message: '名称不能为空' }] }]" placeholder="请输入">
        </a-input>
      </a-form-item>
      <a-form-item label="覆盖物类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select
          showSearch
          :disabled="true"
          v-decorator="['type', { rules: [{ required: true, message: '类型不能为空' }] }]"
          placeholder="请选择覆盖物类型"
        >
          <a-select-option
            v-for="(item, index) in OverlayTypeList"
            :key="index"
            :value="item.value"
            :label="item.label"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="模块类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['systemModule']" :disabled="true" placeholder="请输入"> </a-input>
      </a-form-item>
      <a-form-item label="实例ID" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['instanceId']" :disabled="true" placeholder="请输入"> </a-input>
      </a-form-item>
      <a-form-item label="位置" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea
          v-decorator="['position', { rules: [{ required: true, message: '位置不能为空' }] }]"
          rows="4"
          placeholder="请输入地图描述"
        ></a-textarea>
      </a-form-item>
      <a-form-item label="属性" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea
          v-decorator="['options', { rules: [{ required: true, message: '属性不能为空' }] }]"
          rows="8"
          placeholder="请输入地图描述"
        ></a-textarea>
      </a-form-item>
      <a-form-item label="备注" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea rows="3" v-decorator="['memo']" placeholder="请输入"> </a-textarea>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import { overlayFormVO, overlayTypeList } from './common/common'
import SERVICE_URLS from '@/api/service.url'

export default {
  name: 'GatewayForm',
  props: {
    type: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      OverlayTypeList: overlayTypeList(),
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      spinShow: false,
      formItem: overlayFormVO(),
      form: this.$form.createForm(this),
    }
  },
  computed: {
    //获取当前单位
    currentOrg() {
      return this.$store.getters.currentOrg
    },
    currentUser() {
      return this.$store.getters.currentUser
    },
  },
  created() {},
  methods: {
    setVOFields(values) {
      Object.assign(this.formItem, values)
    },
    setFields(values) {
      Object.assign(this.formItem, values)
      this.form.setFieldsValue({
        name: this.formItem.name,
        type: this.formItem.type,
        systemModule: this.formItem.systemModule,
        instanceId: this.formItem.instanceId,
        memo: this.formItem.memo,
        position: this.formItem.position,
        options: this.formItem.options,
      })
    },
    loadData(id) {
      this.spinShow = true
      this.$http(this, {
        url: SERVICE_URLS.overlay.view,
        params: {
          id: id,
        },
        noTips: true,
        success: (data) => {
          this.$nextTick(() => {
            this.setFields(data.body)
          })
          this.spinShow = false
        },
      })
    },
    submitAdd() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.setVOFields(values)
          this.$http(this, {
            url: SERVICE_URLS.overlay.create,
            noTips: true,
            data: this.formItem,
            success: (data) => {
              this.formItem = overlayFormVO()
              this.$emit('addSuccess')
              this.form.resetFields()
              this.spinShow = false
            },
            error: (data) => {
              this.$emit('addError')
              this.form.resetFields()
              this.spinShow = false
            },
          })
        } else {
          this.spinShow = false
        }
      })
    },
    submitEdit() {
      this.spinShow = true
      this.form.validateFields((err, values) => {
        if (!err) {
          this.setVOFields(values)
          this.$http(this, {
            url: SERVICE_URLS.overlay.update,
            data: this.formItem,
            params: {
              id: this.formItem.id,
            },
            noTips: true,
            success: (data) => {
              this.$emit('editSuccess', data.body)
              this.spinShow = false
            },
            error: (data) => {
              this.$emit('editError')
              this.spinShow = false
            },
          })
        } else {
        }
      })
    },
  },
}
</script>

<style scoped></style>
